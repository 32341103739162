import firebase from 'firebase/app';

export interface Asset {
  id?: string;
  brochure?: string[];
  fundType?: string;
  city: string;
  country: string;
  createdDateTime: firebase.firestore.Timestamp;
  deleted: boolean;
  totalEuroEarnings?: number;
  /**
   * Firestore limitation of nested arrays forces to create intermediate object
   * when `dividendsFormat.contents[0][0] === 'fixed'`, it's fixed net dividends, so contents `length === 1`.
   */
  dividendsFormat: { contents: [string, number] }[];
  emissionCost: number;
  euroMin: number;
  fixedDividends?: boolean;
  floorPlanImages?: string[];
  houseNumber: string;
  images?: string[];
  investmentCase: string;
  name: {
    en: string;
    nl: string;
  }
  postalCode: string;
  premium: boolean;
  propertyDetails: string;
  prospectus?: string[];
  published: boolean;
  preInvestment: boolean;
  returnsAfterEnd?: number;
  sharePrice: number;
  sharesAvailable: number;
  startDateTime: firebase.firestore.Timestamp;
  endDateTime: firebase.firestore.Timestamp;
  street: string;
  totalValueEuro: number;
  totalValueShares: number;
  updatedDateTime?: firebase.firestore.Timestamp;
  reservation?: boolean;
  type: string;
  duration?: number;
  interestRate?: number;
  co2Compensation?: number;
  maxSharesPerInvestor?: number;
  checkoutPaymentOptions: {
    subscription: boolean;
    oneOff: boolean;
    gift: boolean;
  };
  checkoutDescription: {
    nl: string;
    en: string;
  };
  checkoutLinks: {
    termsAndConditions: string;
    bondConditions: string;
    avgPolicy: string;
  };
  activeCampaignTriggerId?: string;
  activeCampaignSubsTriggerId?: string;
  activeCampaignGiftTriggerId?: string;
  activeCampaignRedeemTriggerId?: string;
  subscriptionDiscountTable: {
    sharesAmount: number,
    sharePrice: number,
  }[];
}

export interface ExportRowType {
  transactionDate: string;
  paymentDate: string;
  amount: number | string;
  investorId: number;
  dividendType: string;
  description: string;
}

export interface ExportTableDataType {
  transactionDate: string;
  paymentDate: string;
  amount: number;
  investorId: number;
  investorName: string;
  dividendType?: string;
  description?: string;
  hasErrors: string[];
  errors: string[];
}

export enum AssetType {
  Gras = 'gras',
  Tree = 'tree',
  Bamboo = 'bamboo',
}
