export type PayNLKMSOptions = {
  salesLocation?: true;
  apiToken?: true;
  secret?: true;
  debitApiToken?: true;
  debitSecret?: true; // Incaso (subs)
}

export type PayNLKMSConfig = Record<keyof PayNLKMSOptions, string>;

export type KMSPartialConfig = Partial<PayNLKMSConfig>;

export enum PayNLKMSActions {
  Retrieve = 'retrieve',
  Update = 'update',
}

export interface PayNLRequest {
  action: PayNLKMSActions;
  assetId: string;
  options: PayNLKMSOptions;
  idempotentKey?: string;
}

export interface RetrieveRequest extends PayNLRequest {
  action: PayNLKMSActions.Retrieve;
}

export interface UpdateRequest extends PayNLRequest {
  action: PayNLKMSActions.Update;
}

export type PayNLKMSRequest = RetrieveRequest | UpdateRequest;

export type PayNLKMSReturn = {
  success: true;
  data?: KMSPartialConfig;
}
